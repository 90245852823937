import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SummaryComponent } from './common/summary/summary.component';
import { StatementComponent } from './statement/statement.component';
import { NavigationComponent } from './common/navigation/navigation.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoaderComponent } from './common/loader/loader.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// const appRoutes: Routes = [
//     { path: 'statement', component: StatementComponent },
//     { path: '**', component: PageNotFoundComponent },
// ];

@NgModule({
    declarations: [
        AppComponent,
        SummaryComponent,
        StatementComponent,
        NavigationComponent,
        PageNotFoundComponent,
        DashboardComponent,
        LoaderComponent
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule
        // RouterModule.forRoot(
        //     appRoutes
        // )
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
