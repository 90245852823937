import { Component, OnInit } from '@angular/core';
import { multi } from '../../assets/mocks/heatmap';
import { DashboardService } from '../services/dashboard.service';
import { FormControl } from '@angular/forms';

import * as d3 from 'd3';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public multi: any[];

    public year: string;
    public years = ['2014', '2015', '2016', '2017', '2018', '2019', '2020'];
    public selectYear = new FormControl('Select a year');
    public catData = [];
    // public catLabels = [];
    public catColours: any;
    public annualTotal: number;
    public annualLabels = ['January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'];
    public topPayees = [] as any;
    public topCategories = [] as any;

    constructor(
        private dashboardService: DashboardService
    ) {
        Object.assign(this, { multi });
    }

    async ngOnInit(): Promise<void> {
        this.year = new Date().getFullYear().toString();
        this.getSummary(this.year);
        this.getPayees();
        this.getTopCats();
    }

    getTopCats() {
        this.dashboardService.getTopCategories()
            .subscribe( data => {
                if (data.length > 0) {
                    let i = 0;
                    data.forEach(element => {
                        if (i <= 4) {
                            this.topCategories.push(element);
                            i++;
                        }
                    });
                }
            });
    }

    getPayees() {
        this.dashboardService.getTopPayees()
            .subscribe( data => {
                if (data.length > 0) {
                    let i = 0;
                    data.forEach(element => {
                        if (i <= 4) {
                            this.topPayees.push(element);
                            i++;
                        }
                    });
                }
            });
    }

    getSummary(year: string) {
        this.transactionsByCategory(year);
        this.transactionsByYear(year);
    }

    categoryChart(data) {
        const svgWidth = 600;
        const svgHeight = 500;
        const svgRadius = Math.min(svgWidth, svgHeight) / 3;
        const svg = d3.select('#categorySpending').append('svg').style('overflow', 'visible')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '0 0 ' + Math.min(svgWidth, svgHeight) + ' ' + Math.min(svgWidth, svgHeight))
            .attr('preserveAspectRatio', 'xMinYMin');
        const div = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);
        svg.append('text')
               .attr('transform', 'translate(100, 0)')
               .attr('x', 50).attr('y', 50)
               .text('Annual spending by category');
        const g = svg.append('g')
            .attr('transform', 'translate(' + svgRadius + ', ' + svgHeight / 2 + ')');
        const color = d3.scaleOrdinal(this.colourGradient(.3, .3, .3, 0, 2, 4, 152, 103, 22));
        const pie = d3.pie().sort(null).value(d => d.category_total);
        // const path = d3.arc().innerRadius(0).outerRadius(svgRadius - 10);
        const path = d3.arc().innerRadius(svgRadius * 0.5).outerRadius(svgRadius * 0.8);
        const legendG = svg.selectAll('.legend')
            .data(pie(data))
            .enter().append('g')
            .attr('transform', (d, i) => 'translate(' + (svgWidth * 0.6) + ', ' + (i * 15 + (svgHeight * 0.33)) + ')')
            .attr('class', 'legend');
        legendG.append('rect')
            .attr('width', 10)
            .attr('height', 10)
            .attr('fill', d => color(d.data.category_name));
        legendG.append('text')
            .text(d => d.data.category_name + ' (' + Math.round((d.value / this.annualTotal) * 100) + '%)')
            .style('font-size', 12)
            .attr('y', 10)
            .attr('x', 11);
        const arc = g.selectAll('.arc')
            .data(pie(data))
            .enter().append('g')
            .attr('class', 'arc')
            .on('mouseover', d => {
                div.transition()
                    .duration(200)
                    .style('opacity', .9);
                div.html('£' + d.data.category_total.toLocaleString(undefined, { minimumFractionsDigits: 2 })
                        + ' - ' + d.data.category_name)
                    .style('font-size', '0.75em')
                    .style('left', (d3.event.pageX) + 'px')
                    .style('top', (d3.event.pageY - 28) + 'px');
                })
            .on('mouseout', d => {
                div.transition()
                    .duration(500)
                    .style('opacity', 0);
            })
            .on('click', d => {
                console.log(d);
            });
        arc.append('path')
            .attr('d', path)
            .attr('fill', d => color(d.data.category_name));
    }

    colourGradient(frequency1, frequency2, frequency3, phase1, phase2, phase3, center?, width?, len?) {
        if (center === undefined) { center = 128; }
        if (width === undefined) { width = 127; }
        if (len === undefined) { len = 50; }
        const colArray = [];
        for (let i = 0; i < len; ++i)
        {
            const red = Math.sin(frequency1 * i + phase1) * width + center;
            const grn = Math.sin(frequency2 * i + phase2) * width + center;
            const blu = Math.sin(frequency3 * i + phase3) * width + center;
            colArray.push(this.RGB2Color(red, grn, blu));
        }
        return colArray;
    }

    RGB2Color(r, g, b) {
        return 'rgb(' + Math.round(r) + ',' + Math.round(g) + ',' + Math.round(b) + ')';
    }

    monthlyChart(data) {
        d3.axisBottom();
        d3.axisLeft();
        d3.axisRight();
        d3.axisTop();
        const svgWidth = 600;
        const svgHeight = 500;
        const margin = 200;
        const width = svgWidth - margin;
        const height = svgHeight - margin;
        const svg = d3.select('#monthlyOutgoings').append('svg').style('overflow', 'visible')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '0 0 ' + Math.min(svgWidth, svgHeight) + ' ' + Math.min(svgWidth, svgHeight))
            .attr('preserveAspectRatio', 'xMinYMin');
        const div = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);
        svg.append('text').attr('transform', 'translate(100, 0)').attr('x', 50).attr('y', 50)
            .text('Monthly outgoings (avg. £' + Math.round(this.annualTotal / data.length) + '/month)');
        const xScale = d3.scaleBand().range([0, width]).padding(0.4);
        const yScale = d3.scaleLinear().range([height, 0]);
        const g = svg.append('g').attr('transform', 'translate(50, 100)');
        xScale.domain(this.annualLabels);
        yScale.domain([0, d3.max(data, d => d.monthly_sum )]);
        g.append('g')
            .attr('transform', 'translate(0,' + height + ')')
            .call(d3.axisBottom(xScale).tickValues(this.annualLabels))
            .selectAll('text')
            .style('text-anchor', 'end')
            .attr('dx', '-0.8em')
            .attr('dy', '0em')
            .attr('transform', 'rotate(-45)');
        g.append('g')
            .call(d3.axisLeft(yScale).tickFormat(d => '£' + d )
            .ticks(5));
        g.selectAll('.bar')
            .data(data)
            .enter().append('rect')
            .attr('class', 'bar')
            .attr('fill', '#4dffa6')
            .attr('opacity', 0.5)
            .attr('stroke', '#00cc66')
            .attr('x', d => xScale(d.month ))
            .attr('y', d => yScale(d.monthly_sum ))
            .attr('width', xScale.bandwidth())
            .attr('height', d => height - yScale(d.monthly_sum))
            .on('mouseover', d => {
                div.transition()
                    .duration(200)
                    .style('opacity', .9);
                div.html('£' + d.monthly_sum.toLocaleString(undefined, { minimumFractionsDigits: 2 }))
                    .style('font-size', '0.75em')
                    .style('left', (d3.event.pageX) + 'px')
                    .style('top', (d3.event.pageY - 28) + 'px');
                })
            .on('mouseout', d => {
                div.transition()
                    .duration(500)
                    .style('opacity', 0);
            });
    }

    transactionsByCategory(year: string) {
        this.dashboardService.getTransactionsByCategoryByYear(year)
            .subscribe( categories => {
                this.catColours = [];
                this.catData = categories;
                if (this.catData.length > 0){
                    this.annualTotal = 0;
                    let total = 0;
                    const tempCol = [];
                    categories.forEach((element, index) => {
                        tempCol.push('hsl(' + index + '0, 100%, 66%)');
                        this.annualTotal += element.category_total;
                    });
                    total = this.annualTotal;
                    this.catColours = tempCol;
                    this.categoryChart(this.catData);
                }
            });
    }

    transactionsByYear(year: string) {
        this.dashboardService.getMonthlyTransactionsByYear(year)
            .subscribe( data => {
                if (data.length > 0){
                    data.map(element => (element.month = this.annualLabels[element.month - 1]));
                    this.monthlyChart(data);
                }
            });
    }

    yearSelected(e) {
        // this.annualTotal = undefined;
        d3.selectAll('#monthlyOutgoings > *').remove();
        d3.selectAll('#categorySpending > *').remove();
        this.getSummary(this.selectYear.value);
        this.year = this.selectYear.value;
    }


}
