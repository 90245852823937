import { Component, OnInit } from '@angular/core';
import { StatementService } from '../services/statement.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { runInThisContext } from 'vm';

@Component({
    selector: 'app-statement',
    templateUrl: './statement.component.html',
    styleUrls: ['./statement.component.scss' ]
})
export class StatementComponent implements OnInit {

    public total = 0 as number;
    public info: any;
    public shadowTransactions = [] as any;
    public transactions = [] as any;
    public page = {
        pageSize: 10, 
        content: [], 
        pageIndex: 1,
        numberOfPages: 0
    };
    public frmFilters: FormGroup;
    public categories: any;
    public subcategories: any;
    public filter = {
        accounts: [],
        categories: [],
        subcategories: []
    };
    public sorted = false as boolean;
    constructor(
        private formBuilder: FormBuilder,
        private statementService: StatementService
    ) { }

    ngOnInit(): void {
        this.accountsInfo();
        this.categoriesList();
        this.subcategoriesList();
        this.statementTransactions();
        this.frmFilters = this.formBuilder.group({});
    }

    accountsInfo(): void {
        this.statementService.getAccountsInfos()
            .subscribe( data => {
                this.info = data;
                this.info.forEach(element => {
                    this.frmFilters.addControl('account' + element.id, this.formBuilder.control(false));
                });
            });
    }

    categoriesList(): void {
        this.statementService.getCategories()
            .subscribe( data => {
                this.categories = data;
            });
    }

    subcategoriesList(): void {
        this.statementService.getSubCategories()
            .subscribe( data => {
                this.subcategories = data;
            });
    }

    filterByAccount(e: any, a: number) {
        const i = this.filter['accounts'].indexOf(e.target.value);
        if (i > -1) {
            let transactions = this.transactions;
            this.filter['accounts'].splice(i, 1);
            if (this.filter['categories'].length > 0) {
                this.filter['categories'].forEach(category => {
                    transactions.filter(el => el.category_name !== category);
                });
            }
            this.filter['accounts'].forEach(account => {
                transactions = transactions.filter(transaction => transaction.account !== Number(account));
            });
            this.shadowTransactions = transactions;
            // this.shadowTransactions = Array.from(new Set(this.shadowTransactions.concat(this.transactions)));
        } else {
            this.shadowTransactions = this.shadowTransactions.filter(el => el.account !== a);
            this.filter['accounts'].push(e.target.value);
        }
        this.page.content = this.shadowTransactions.slice(0, this.page.pageSize);
        this.page.numberOfPages = Math.round(this.shadowTransactions.length / this.page.pageSize);
        this.page.pageIndex = 1;
    }

    filterByCategory(e: any) {
        this.filter['categories'] = [];
        if (this.filter['accounts'].length > 0) {
            this.filter['accounts'].forEach(account => {
                this.shadowTransactions = this.transactions.filter(transaction => transaction === account);
            });
        }
        console.log(this.shadowTransactions);
        if (e.target.value === 'all') {

        } else {
            this.filter['categories'].push(e.target.value);
            this.shadowTransactions = this.shadowTransactions.filter(el => el.category_name === e.target.value);
        }
        this.page.content = this.shadowTransactions.slice(0, this.page.pageSize);
        this.page.numberOfPages = Math.round(this.shadowTransactions.length / this.page.pageSize);
        this.page.pageIndex = 1;
    }

    // filterBySubcategory(e: any) {
    //     console.log(e);
    //     this.shadowTransactions = this.shadowTransactions.filter(el => el.subcategory_name === e.target.value);
    //     this.page.content = this.shadowTransactions.slice(0, this.page.pageSize);
    //     this.page.numberOfPages = Math.round(this.shadowTransactions.length / this.page.pageSize);
    //     this.page.pageIndex = 1;
    // }

    filterPayees(e: any) {
        let transactions = this.transactions;
        let newArray = transactions.filter(obj => obj.payee.toLowerCase().includes(e.target.value.toLowerCase()));
        if (newArray.length > 0) {
            this.shadowTransactions = newArray;
        } else {
            this.shadowTransactions = this.transactions;
        }
        this.page.content = this.shadowTransactions.slice(0, this.page.pageSize);
        this.page.numberOfPages = Math.round(this.shadowTransactions.length / this.page.pageSize);
        this.page.pageIndex = 1;
    }

    goToPage(e: any, i: number) {
        e.preventDefault();
        if (i >= this.shadowTransactions.length) {
            this.page.content = this.shadowTransactions.slice((this.shadowTransactions.length - this.page.pageSize), (this.shadowTransactions.length + this.page.pageSize));
        } else if (i <= 1) {
            this.page.content = this.shadowTransactions.slice(0, this.page.pageSize);
        } else {
            this.page.content = this.shadowTransactions.slice(((i - 1) * 10), (i * this.page.pageSize));
        }
        this.page.pageIndex = i;
    }

    parseDbDate(s) {
        return new Date(s);
    }

    parseJsonDate(s) {
        return new Date(JSON.parse(JSON.stringify(s)));
    }

    resetView(e: any) {
        e.preventDefault();
        Object.keys(this.frmFilters.controls).forEach((key, value) => {
            if (key.substr(0, 7) === 'account') {
                this.frmFilters.controls[key].setValue(true);
            }
        });
        this.page = {
            content: this.transactions.slice(0, this.page.pageSize),
            pageSize: 10,
            pageIndex: 1,
            numberOfPages: Math.round(this.transactions.length / this.page.pageSize)
        }
    }

    statementTransactions(): void {
        this.statementService.getStatement()
            .subscribe( data => {
                this.page.content = data.slice(0, this.page.pageSize);
                this.page.numberOfPages = Math.round(data.length / this.page.pageSize);
                this.transactions = data;
                this.shadowTransactions = data;
            });
    }

    sortBy(e: any, s: string) {
        console.log(e);
        let clsName = e.srcElement.className;
        if (this.sorted === true) {
            this.shadowTransactions.reverse();
            this.page.content = this.shadowTransactions.slice(0, this.page.pageSize);
            (clsName === 'asc') ? e.srcElement.className = 'desc' : e.srcElement.className = 'asc';
        } else {
            this.shadowTransactions.sort((a, b) => {
                if (a[s] < b[s]) {
                    return -1;
                }
                if (a[s] > b[s]) {
                    return 1;
                }
                return 0;
            });
            this.page.content = this.shadowTransactions.slice(0, this.page.pageSize);
            this.sorted = true;
            e.srcElement.className = 'asc';
        }
    }

}
