import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    private apiUrl: string = environment.apiUrl;

    constructor(
        private http: HttpClient
    ) { }

    getCategories(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/common/categories.php');
    }

    getMonthlyTransactionsByYear(year: string): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/dashboard/monthlyTransactionsByYear.php?year=' + year);
    }

    getTopCategories(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/dashboard/topCategories.php');
    }

    getTopPayees(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/dashboard/topPayees.php');
    }

    getTransactionsByCategoryByYear(year: string): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/dashboard/summaryByCategory.php?year=' + year);
    }

    getTransactionsBySubcategoryByYear(year: string): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/dashboard/summaryBySubcategory.php?year=' + year);
    }

}
