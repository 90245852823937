<!-- To add: cash flow, debt reduction plan -->

<div class="row">
    <div class="col">
        <h3>Top five payees</h3>
        <ol>
            <li *ngFor="let payee of topPayees">{{ payee.payee }} ({{ payee.spending | currency: 'GBP' }})</li>
        </ol>
    </div>
    <div class="col">
        <h3>Top five categories</h3>
        <ol>
            <li *ngFor="let category of topCategories">{{ category.category_name | titlecase }} ({{ category.spending | currency: 'GBP' }})</li>
        </ol>
    </div>
</div>

<form action="form-inline">
    <div class="row pt-3 pb-3">
        <div class="col-sm-2">
            <label for="selectYear">Select a year:</label>
        </div>
        <div class="col-sm-2">
            <select class="custom-select" [formControl]="selectYear" (change)="yearSelected($event)">
                <option *ngFor="let year of years" value="{{ year }}">{{ year }}</option>
            </select>
        </div>
    </div>
</form>

<div class="row">
    <div class="col">
        <h3 *ngIf="annualTotal">Year {{ year }} total: {{ annualTotal | currency: 'GBP' }}</h3>
        <h3 *ngIf="!annualTotal">No data for {{ year }}</h3>
    </div>
</div>

<div class="row">
    <div id="categorySpending" class="col-lg-6 mx-auto"></div>
    <div id="monthlyOutgoings" class="col-lg-6 mx-auto"></div>
</div>

<!-- <div class="row">
    <div class="col">Annual outgoings by category</div>
    <div class="col">Annual outgoings by sub-category</div>
</div> -->
