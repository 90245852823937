<form [formGroup]="frmFilters" novalidate>
    <div class="row">
        <div class="col">
            <h3>Filters</h3>
        </div>
    </div>
    <div class="row pt-3">
        <div class="col">
            <div class="form-check" *ngFor="let account of info">
                <input type="checkbox" class="form-check-input" name="account{{account.id}}" formControlName="account{{account.id}}" value="{{account.id}}" (change)="filterByAccount($event, account.id)">
                <label class="form-check-label" for="account{{account.id}}">{{ account.name }} ({{ account.owner_name }} {{ account.owner_surname }})</label>
            </div>
        </div>
        <div class="col">
            <div class="form-group">
                <label for="categories">Categories:</label>
                <select name="categories" id="categories" class="form-control" (change)="filterByCategory($event)">
                    <option value="all">--- All ---</option>
                    <option *ngFor="let category of categories" value="{{category.name}}">{{ category.name }}</option>
                </select>
            </div>
        </div>
        <!-- <div class="col">
            <div class="form-group">
                <label for="subcategories">Subcategories:</label>
                <select name="subcategories" id="subcategories" class="form-control" (change)="filterBySubcategory($event)">
                    <option value="all">--- All ---</option>
                    <option *ngFor="let subcategory of subcategories" value="{{subcategory.name}}">{{ subcategory.name }}</option>
                </select>
            </div>
        </div> -->
        <div class="col">
            <div class="form-group">
                <label for="search">Search (min. 3 characters):</label>
                <input class="form-control" type="text" name="search" id="search" (keyup)="filterPayees($event)">
            </div>
        </div>
    </div>
</form>
<!-- {{ frmFilters.value | json }} -->
<div class="row pt-3">
    <div class="col"> (<a (click)="resetView($event)" href="#">Reset view</a>) To be added: search, more filters</div>
</div>
<div class="row pt-3">
    <div class="col">
        <div *ngIf="!shadowTransactions">
            <app-loader></app-loader>
        </div>
        <div *ngIf="shadowTransactions" class="table-responsive">
            <table class="table table-hover table-sm">
                <thead>
                    <tr>
                        <th scope="col" (click)="sortBy($event,'date')">Date <span></span></th>
                        <th scope="col" (click)="sortBy($event,'payee')">Payee <span></span></th>
                        <th scope="col" (click)="sortBy($event,'payer')">Payer <span></span></th>
                        <th scope="col" (click)="sortBy($event,'category_name')">Category <span></span></th>
                        <th scope="col" (click)="sortBy($event,'subcategory_name')">Subcategory <span></span></th>
                        <th scope="col" (click)="sortBy($event,'credit')">Credit <span></span></th>
                        <th scope="col" (click)="sortBy($event,'debit')">Debit <span></span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let t of page.content">
                        <td>{{ parseDbDate(t.date) | date }}</td>
                        <td>{{ t.payee }}</td>
                        <td>{{ t.payer }}</td>
                        <td>{{ t.category_name }}</td>
                        <td>{{ t.subcategory_name }}</td>
                        <td>&pound; {{ t.credit }}</td>
                        <td>&pound; {{ t.debit }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="4"></td>
                        <td>Page totals:</td>
                        <td>TBA</td>
                        <td>TBA</td>
                    </tr>
                </tfoot>
            </table>
            <nav aria-label="Page navigation">
                <div class="d-flex justify-content-between">
                    <p>Page {{ page.pageIndex }} of {{ page.numberOfPages }}</p>
                    <ul class="pagination">
                        <li class="page-item" [ngClass]="{'disabled': page.pageIndex === 1}"><a class="page-link" href="#" (click)="goToPage($event, 1)">First</a></li>
                        <li class="page-item" [ngClass]="{'disabled': page.pageIndex === 1}"><a class="page-link" href="#" (click)="goToPage($event, (page.pageIndex - 1))">Previous</a></li>
                        <li class="page-item" [ngClass]="{'disabled': page.pageIndex >= page.numberOfPages}"><a class="page-link" href="#" (click)="goToPage($event, (page.pageIndex + 1))">Next</a></li>
                        <li class="page-item" [ngClass]="{'disabled': page.pageIndex >= page.numberOfPages}"><a class="page-link" href="#" (click)="goToPage($event, page.numberOfPages)">Last</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>