import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SummaryComponent } from './common/summary/summary.component';
import { StatementComponent } from './statement/statement.component';
// import { NavigationComponent } from './common/navigation/navigation.component';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const appRoutes: Routes = [
    { path: 'statement', component: StatementComponent },
    { path: 'dashboard', component: DashboardComponent },
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: '**', component: PageNotFoundComponent },
];

@NgModule({
    declarations: [],
    imports: [
        // CommonModule,
        RouterModule.forRoot(
            appRoutes
        )
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule { }
