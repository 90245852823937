import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StatementService {

    private apiUrl: string = environment.apiUrl;

    constructor(
        private http: HttpClient
    ) { }

    getCategories(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/common/categories.php');
    }

    getSubCategories(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/common/subcategories.php');
    }

    getStatement(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/transactions/read.php');
    }

    getAccountsInfos(): Observable<any> {
        return this.http.get<any>(this.apiUrl + '/common/accountsInfo.php');
    }
}
