export const multi = [
    {
        name: '1',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '2',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '3',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '4',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '5',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '6',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '7',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '8',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '9',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    },
    {
        name: '10',
        series: [
            {
            name: 'monday',
            value: 40632
            },
            {
            name: 'tuesday',
            value: 36953
            },
            {
            name: 'wednesday',
            value: 31476
            },
            {
            name: 'thursday',
            value: 31476
            },
            {
            name: 'friday',
            value: 31476
            },
            {
            name: 'saturday',
            value: 31476
            },
            {
            name: 'sunday',
            value: 31476
            }
        ]
    }
];
